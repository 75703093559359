import React from "react";
import styles from "./TnC.module.css";

const TnC = () => {
  return (
    <div className={styles.tnc}>
      <div className={styles.heading}>Terms &amp; Conditions</div>
      Last updated: 31 July 2022 <br />
      <br />
      Welcome to www.homecookd.org
      <br />
      <br />
      This page (together with the documents referred to in it) tells you the
      terms (the “Terms”) which apply when you order any menu items (the
      “Items”) from our homecookd.org website or mobile applications and related
      services (each referred to as an “Application”).
      <br />
      Please read these Terms carefully before creating a HomeCookd account or
      using our Application. If you have any questions relating to these Terms
      please contact marketing@homecookd.org . If you are a consumer, you have
      certain legal rights when you order Items using our Application. Your
      legal rights are not affected by these Terms, which apply in addition to
      them and do not replace them. By setting up your HomeCookd’s account, you
      confirm that you accept these Terms.
      <br />
      <br />
      <h1>1. Information About Us</h1>
      homecookd.org is operated by HomeCookd Ltd, a company incorporated and
      registered in the Netherlands, whose registered office is at
      Zeeburgerstraat 64, 1018AG, Amsterdam, Netherlands. You may contact us at
      marketing@homecookd.org <br />
      <br />
      <h1>2. Purpose </h1>
      Our objective is to link you to the businesses we partner with
      (“Partners”) and allow you to order Items for delivery (our “Service”).
      Where you order from a Partner, HomeCookd’s acts as an agent on behalf of
      that Partner to conclude your order from our Application and to manage
      your experience throughout the order process. Once you have placed an
      order, delivery of your Items will be arranged by HomeCookd (“HomeCookd
      Delivery”) or our Partner (“Partner Delivery) (each a “Delivery”)
      depending on the Partner you have selected. In some cases, the Partner may
      be owned by or affiliated with us. <br />
      <br />
      <h1>3. Your Account</h1>
      Before you can place orders for Items using our Application, you need to
      open a HomeCookd account. When you open an account you may create a
      password, or other secure login method, and may also have to provide
      credit card details. You must keep any password you create, or other
      secure login method, secret, and prevent others from accessing your email
      account or mobile phone. If another person uses these methods to access
      your account, you will be responsible to pay for any Items they order, and
      we are not responsible for any other losses you suffer, unless the person
      using your password obtained it because we did not keep it secure.
      <br />
      You may close your account at any time by requesting to do so in your
      account section of our website or contacting us using the contact details
      above. We may suspend your access to your account, or close it
      permanently, if we believe that your account has been used by someone
      else. We may also close your account if in our opinion you are abusing our
      Service (for example, by applying for refunds or credit to which we do not
      consider you are entitled, making repeated unreasonable complaints,
      mistreating our staff or drivers, or any other good reason). If we close
      your account permanently we will refund any remaining account credit you
      have validly obtained from our customer service team or Application
      following any issue with an order, by applying a credit to your registered
      credit card, or if that is not possible for any reason, by way of a bank
      transfer using bank details (provided you have supplied them to us).
      <br />
      <br />
      <h1>4. Service Availability </h1>
      Each Partner has a prescribed delivery area. This delivery area may change
      at any time due to factors such as weather, or demand on our service. This
      is to ensure that Items reach your door at their best. Our Partners each
      decide their own operating hours. That means that the availability of our
      Service, and the range of Partners from which you can order, depends on
      the Partners in your area. If you try to order a delivery to a location
      outside the delivery area or operating hours of a Partner, or the
      Application is otherwise unavailable for any reason, we will notify you
      that ordering will not be possible.
      <br />
      <br />
      <h1>5. Orders</h1>
      When you place an order through our Site or Application, it needs to be
      accepted by us or the Partner before it is confirmed. You are responsible
      for paying for all Items ordered using your account, and for related
      delivery charges, and for complying with these Terms, even if you have
      ordered the Item for someone else. Some Partners operate a minimum order
      value policy. This will be displayed on our Site or Application. All Items
      are subject to availability. Partners may use nuts or other allergens in
      the preparation of certain Items. Increasing numbers of Partners will be
      displaying dish by dish allergens information. Where that information is
      not available or if you have further questions, please contact the Partner
      prior to ordering if you have an allergy. HomeCookd cannot guarantee that
      any of the Items sold by our Partners are free of allergens. <br />
      <br />
      <h1>6. Delivery</h1>
      When you place an order you will have delivery date options. We will tell
      you the time when the Item is expected to be delivered; you must be
      available to accept delivery for one hour before and one hour after that
      time. <br />
      Unfortunately, despite our, and our Partner’s best efforts, things do not
      always go to plan and factors such as traffic and weather conditions may
      prevent us from delivering your Item on time. If your order is more than 2
      hours late, and we haven’t notified you giving you the option to cancel
      your order, we will work with you to make things right unless you have
      caused the delay (e.g. because you gave us the wrong address or did not
      come to the door).
      <br />
      We will attempt delivery at the address you provide to us when you place
      your order. If you need to change the delivery location after you have
      placed your order, we may be able to change to the address to an
      alternative one that is registered with your account if you let us know
      before the driver has been dispatched, and the new address is within the
      same zone as the address you originally ordered your Item to. If we cannot
      change the delivery address, you have the option to cancel the order, but
      if food preparation has started you will be charged the full price for the
      Item, and if the driver has been dispatched you will also be charged for
      delivery.
      <br />
      You will still be charged for the Item and for delivery in the event of a
      failed delivery if you have caused such failure for any reason. Reasons
      you might cause a delivery to fail include (but are not limited to):
      <ul>
        <li>
          You do not come to the door, did not pick up the phone when the driver
          contacted you using the contact information you have provided us
          and/or you picked up the phone but then failed to provide access
          within a reasonable amount of time, and the driver is unable to find a
          safe location to leave the food.
        </li>
        <li>
          The driver refuses to deliver the Item to you in accordance with
          section 8 (Age Restricted Products).
        </li>
      </ul>
      <br />
      <br />
      <h1>7. Your Rights if Something is Wrong With Your Items</h1>
      You have a legal right to receive goods which comply with their
      description, which are of satisfactory quality and which comply with any
      specific requirements you tell us about (and we agree to) before you place
      your order. If you believe that the Items you have been delivered do not
      comply with these legal rights, please let us know. We may request a
      photograph showing the problem if it is something that can be seen by
      inspecting the Items. We will provide a refund or account credit in
      respect of the affected part of the Item, and also in respect of delivery
      if the whole order was affected, unless we have reasonable cause to
      believe that the problem was caused after delivery.
      <br />
      Prior to processing your refund or account credit, we may take into
      account relevant factors including the details of the order, including
      your account history, what happened on delivery and information from the
      Partner.
      <br />
      <h1>8. Age Restricted and Regulated Products </h1>
      Age restricted products (including, without limitation, alcohol, tobacco
      and cigarettes) can only be sold and delivered to persons aged 18 or over.
      By placing an order for an age restricted product, you confirm that you
      are at least 18 years old. HomeCookd operates an age verification policy
      whereby customers ordering age restricted products will be asked by the
      driver to provide proof that they are aged 18 or over before the delivery
      is completed. The driver may refuse to deliver any age restricted product
      to any person unless they can provide a valid photo ID proving that they
      are aged 18 or over. The Partner and the driver may refuse to deliver
      alcohol to any person who is, or appears to be under the influence of
      either alcohol or drugs. If delivery of any age restricted product is
      refused, you will still be charged for the relevant Item and for delivery.
      <br />
      <br />
      <h1>9. Cancellation</h1> If you cancel any order after it is placed, you
      will be charged the full price for the Items, and if the driver has been
      dispatched you will also be charged for delivery.
      <br /> HomeCookd and the Partner may notify you that an order has been
      cancelled at any time. You will not be charged for any orders cancelled by
      us or the Partner, and we will reimburse you for any payment already made
      using the same method you used to pay for your order. We may also apply
      credit to your account to reflect the inconvenience caused. <br />
      <br />
      <h1>10. Prices, Payment and Offers </h1>
      Prices include VAT. You confirm that you are using our Service for
      personal, non-commercial use unless you request a VAT invoice. HomeCookd
      may operate dynamic pricing some of the time, which means that prices of
      Items and delivery may change while you are browsing. Prices can also
      change at any time at the discretion of the Partner. We reserve the right
      to charge a Service Fee, which may be subject to change, for the provision
      of our Services. You will be notified of any applicable Service Fee and
      taxes prior to purchase on the checkout page on our Application. No
      changes will affect existing confirmed orders, unless there is an obvious
      pricing mistake. Nor will changes to prices affect any orders in process
      and appearing within your basket, provided you complete the order within 2
      hours of creating the basket. If you do not conclude the order before the
      2 hour cut-off the items will be removed from your basket automatically
      and the price change will apply. If there is an obvious pricing mistake we
      will notify you as soon as we can and you will have the choice of
      confirming the order at the original price or cancelling the order without
      charge and with a full refund of any money already paid. Where HomeCookd
      or any Partner makes a delivery, we or the Partner may also charge you a
      delivery fee. This will be notified to you during the order process before
      you complete your order. <br />
      The total price of your order will be set out on the checkout page on our
      Site or Application, including the prices of Items and Delivery and
      applicable Service Fees and taxes.
      <br />
      Payment for all Items and deliveries can be made on our Application by
      credit or debit card, or other payment method made available by HomeCookd.
      Once your order has been confirmed your credit or debit card will be
      authorised and the total amount marked for payment. Payment is made
      directly to HomeCookd acting as agent on behalf of the Partner only.
      Payment may also be made by using vouchers or account credit. Use of these
      is subject to HomeCookd Voucher and Account Credit Terms. <br />
      We are authorised by our Partners to accept payment on their behalf and
      payment of the price of any Items or delivery charges to us will fulfil
      your obligation to pay the price to the Partner. In some cases, you can
      alternatively make your payment in cash directly to the Partner by paying
      the driver at the time of delivery. Where cash payment is possible, this
      will be made clear on our Application before you place your order.
      <br />
      Partners sometimes make special offers available through our Site or
      Application. These are visible when you look at a Partner menu. These
      offers are at the discretion of the Partner. Unless the offer terms state
      a fixed or minimum period for which an offer will be available, it can be
      withdrawn at any time, unless you have already placed an order based on
      the offer and we have sent the Confirmation Notice. <br />
      <br />
      <h1>11. Subscription plan</h1>
      <br /> <h1>11.1. Billing Cycle</h1> You agree to pay for the plan price
      set by you for the HomeCookd Subscription service, that will be charged to
      your Payment Method on a weekly basis every Friday. In some cases your
      payment date may change, for example if your Payment Method has not
      successfully settled. Visit your account and click on the “Subscription”
      link to see your next payment date.
      <br />
      <br />
      <h1>11.2. Payment Methods</h1> To use the HomeCookd Subscription service
      you must provide one Payment Method. You are responsible for any
      uncollected amounts. You agree to immediately update your Account in the
      event of any change in your payment information. If a payment is not
      successfully settled, due to expiration, insufficient funds, or otherwise,
      and you do not cancel your account, we may suspend your access to the
      HomeCookd Subscription service until we have successfully charged a valid
      Payment Method. For some Payment Methods, the issuer may charge you
      certain fees, such as foreign transaction fees or other fees relating to
      the processing of your Payment Method. Local tax charges may vary
      depending on the Payment Method used. Check with your Payment Method
      service provider for details.
      <br />
      <br />
      <h1>11.3. Updating your Payment Methods</h1> You can update your Payment
      Methods by going to the “Account” page. We may also update your Payment
      Methods using information provided by the payment service providers.
      Following any update, you authorize us to continue to charge the
      applicable Payment Method.
      <br />
      <br /> <h1>11.4. Cancelling your Subscriptions </h1>You can cancel your
      HomeCookd Subscription service at any time and you will continue to have
      access to the HomeCookd Subscription service through the end of your
      Billing Cycle.
      <br />
      To cancel, go to the “Account” page and follow the instructions for
      cancellation. If you cancel your subscription, your account will
      automatically be updated at the end of your current billing period. <br />
      You should take into account that any cancellation should be done before
      the next Billing Cycle: the weekly cut-off time is set at 11 am (GMT)
      every Friday. <br />
      Payments are non-refundable and we do not provide refunds or credits for
      any already paid but uncollected meals from a Billing Cycle.
      <br />
      <br />
      <h1>11.5. Changes to the Price and Subscription Plans</h1> From time to
      time we may change our Subscription Plans and the price of our service
      (Meal Prices); however, any price changes or changes to your Subscription
      Plans will apply no earlier than 10 days following our notice to you.{" "}
      <br />
      <br />
      <h1>12. Our Responsibility for Loss or Damage That You Suffer</h1>
      We are responsible to you for any loss or damage that you suffer that is a
      foreseeable result of our breaking these Terms or of failing to use
      reasonable care and skill in relation to your use of our Service. We are
      not responsible for any loss or damage that is not foreseeable. Loss or
      damage is “foreseeable” if it is either obvious that it will happen, or if
      you told us that it might happen, for example if you tell us about
      particular circumstances that might increase the loss or damage arising
      from our breach of these Terms before you place an order. <br />
      We do not exclude or limit our responsibility to you for loss or damage
      where it would be unlawful to do so. This includes any responsibility for
      death or personal injury caused by our failure, or our employees’, agents’
      or subcontractors’ failure, to use reasonable care and skill; for fraud or
      fraudulent misrepresentation; for breach of your legal rights in relation
      to the Items, as summarised at part 7 above; or for defective Items under
      the Consumer Protection Act. Subject to the previous paragraph, we are not
      responsible for any loss or damage that you suffer as a result of your own
      breach of these Terms or by the Partners, or as a result of any IT
      hardware or software failure other than a failure in our Site or
      Applications. <br />
      <br />
      <h1>13. Data Protection</h1> We process your personal data in accordance
      with our Privacy Policy which can be found on our Site. <br />
      <br />
      <h1>14. Other Terms</h1>
      If either we or you have any right to enforce these Terms against the
      other, that right will not be lost even if the person who has the right
      delays enforcing it, or waives their right to enforce it in any instance.
      If a court or other authority decides that any part of these Terms is
      illegal or ineffective, the rest of the terms will be unaffected and will
      remain in force.
      <br />
      We may change these Terms from time to time. If we make any changes which
      affect your rights in relation to our Service, we will notify you. Changes
      to the Terms will not affect any orders you have placed where we have sent
      the Confirmation Notice. These Terms are governed by Dutch law and you can
      bring legal proceedings in relation to our Service in the Dutch courts.
      <br />
      <br />
      <h1>HomeCookd Terms Of Use For Website And Applications </h1>
      This page (together with the documents referred to on it) tells you the
      terms of use on which you may make use of our website homecookd.org (our
      “Site”) or any application we make available via an app store or otherwise
      (our “Service”), whether as a guest or a registered user. Please read
      these terms of use carefully before you start to use or Site or our
      Service. By accessing our Site or by using our Service, you indicate that
      you accept these terms of use and that you agree to abide by them. If you
      do not agree to these terms of use, do not use our Site or use our
      Service.
      <br />
      <br />
      <h1>1. Information About Us</h1>
      homecookd.org is a website operated by HomeCookd Ltd (“we”, “us” or
      “HomeCookd”), incorporated and registered in the Netherlands, whose
      registered office is at Zeeburgerstraat 64, Amsterdam, Netherlands.
      HomeCookd is a business where the food is prepared by independent cooking
      partners (our “Partners”) and delivered by us.
      <br />
      <br /> <h1>2. Accessing Our Service Or Our Services</h1> Access to our
      Site and to our Service is permitted on a temporary basis, and we reserve
      the right to withdraw or amend access to our Site or our Service without
      notice (see below). We will not be liable if, for any reason, our Site or
      our Service is unavailable at any time or for any period. From time to
      time, we may restrict access to some parts of our Site or our Service, or
      our entire Site or Service to users who have registered with us. You are
      responsible for maintaining the confidentiality of your login details and
      any activities that occur under your account. If you have any concerns
      about your login details or think they have been misused, you should
      contact marketing@homecookd.org straight away to let us know. We can
      deactivate your account at any time.
      <br />
      <br />
      <h1>3. Acceptable Use</h1> You may use our Service only for lawful
      purposes. You may not use our Site or our Service in any way that breaches
      any applicable local, national or international law or regulation or to
      send, knowingly receive, upload, download, use or re-use any material
      which does not comply with our content standards in clause 5 below. You
      also agree not to access without authority, interfere with, damage or
      disrupt any part of our Site or our Service or any network or equipment
      used in the provision of our Service.
      <br />
      <br /> <h1>4. Interactive Features Of Our Site</h1> We may from time to
      time provide certain features which allow you to interact through our Site
      or our Service such as chat rooms. Generally, we do not moderate any
      interactive service we provide although we may remove content in
      contravention of these Terms of Use as set out in section 6. If we do
      decide to moderate an interactive service, we will make this clear before
      you use the service and normally provide you with a means of contacting
      the moderator, should a concern or difficulty arise.
      <br />
      <br /> <h1>5. Content Standards</h1>
      These content standards apply to any and all material which you contribute
      to our Service (the “Contributions”), and to any interactive services
      associated with it. You must comply with the spirit of the following
      standards as well as the letter. The standards apply to each part of any
      Contributions as well as to its whole. Contributions must be accurate
      (where they state facts), be genuinely held (where they state opinions)
      and comply with applicable law in the UK and in any country from which
      they are posted. Contributions must not: <br />
      <ul>
        <li>
          contain any material which is defamatory of any person, obscene,
          offensive, hateful or inflammatory, promote sexually explicit material
          or promote violence or promote discrimination based on race, sex,
          religion, nationality, disability, sexual orientation or age;
        </li>
        <li>
          infringe any copyright, database right or trademark of any other
          person;
        </li>
        <li>
          be likely to deceive any person or be made in breach of any legal duty
          owed to a third party, such as a contractual duty or a duty of
          confidence or promote any illegal activity;
        </li>
        <li>
          be threatening, abuse or invade another’s privacy, or cause annoyance,
          inconvenience or needless anxiety or be likely to harass, upset,
          embarrass, alarm or annoy any other person;
        </li>
        <li>
          be used to impersonate any person, or to misrepresent your identity or
          affiliation with any person or give the impression that they emanate
          from us, if this is not the case; or advocate, promote or assist any
          unlawful act such as (by way of example only) copyright infringement
          or computer misuse.
        </li>
      </ul>{" "}
      <br />
      <br />
      <h1>6. Suspension And Termination </h1>Failure to comply with section 3
      (Acceptable Use) and/or 5 (Content Standards) in these Terms of Use
      constitutes a material breach of the Terms of Use, and may result in our
      taking all or any of the following actions: <br />
      <ul>
        <li>
          immediate, temporary or permanent withdrawal of your right to use our
          Service;
        </li>{" "}
        <li>
          immediate, temporary or permanent removal of any posting or material
          uploaded by you to our Service;
          <br /> issuing of a warning to you;
        </li>
        <li>
          legal action against you including proceedings for reimbursement of
          all costs on an (including, but not limited to, reasonable
          administrative and legal costs) resulting from the breach;
        </li>
        <li>
          disclosure of such information to law enforcement authorities as we
          reasonably feel is necessary
        </li>
        .
      </ul>
      The responses described in this clause are not limited, and we may take
      any other action we reasonably deem appropriate. <br />
      <br />
      <h1>7. Intellectual Property Rights</h1> We are the owner of or the
      licensee of all intellectual property rights in our Site and our Service,
      and in the material published on it (excluding your Contributions). Those
      works are protected by copyright laws and treaties around the world. All
      such rights are reserved. You may not copy, reproduce, republish,
      download, post, broadcast, transmit, make available to the public, or
      otherwise use any content on our site in any way except for your own
      personal, non-commercial use. <br />
      <br />
      <h1>8. Reliance On Information Posted</h1> Commentary and other materials
      posted on our Service are not intended to amount to advice on which
      reliance should be placed. We therefore disclaim all liability and
      responsibility arising from any reliance placed on such materials by any
      visitor to our Service, or by anyone who may be informed of any of its
      contents. <br />
      <br />
      <h1>9. Our Site And Our Service Change Regularly</h1>
      We aim to update our Site and our Service regularly, and may change the
      content at any time. If the need arises, we may suspend access to our Site
      and our Service, or close them indefinitely. Any of the material on our
      Site or our Service may be out of date at any given time, and we are under
      no obligation to update such material. <br />
      <br />
      <h1>10. Our Liability </h1>We have taken every care in the preparation of
      our Site and our Service. However, we will not be responsible for any
      errors or omissions in relation to such content or for any technical
      problems you may experience with our Site or our Service. If we are
      informed of any inaccuracies on our Site or in our Service we will attempt
      to correct this as soon as we reasonably can. To the extent permitted by
      law, we exclude all liability (whether arising in contract, in negligence
      or otherwise) for loss or damage which you or any third party may incur in
      connection with our Site, our Service, and any website linked to our Site
      and any materials posted on it. This does not affect our liability for
      death or personal injury arising from our negligence, or our liability for
      fraudulent misrepresentation or misrepresentation as to a fundamental
      matter, or any other liability which cannot be excluded or limited under
      applicable law.
      <br />
      <br />
      <h1>
        11. Information About You And Your Visits To Our Site And Use Of Our
        Service
      </h1>{" "}
      We collect certain data about you as a result of you using our Service.
      This is described in more detail in our privacy policy.
      <br />
      <br />
      <h1>12. Uploading Material To Our Site And Our Service </h1>
      Any material you upload to our Service or data that we collect as set out
      above (section 11) will be considered non-confidential and
      non-proprietary, and you acknowledge and agree that we have the right to
      use, copy, distribute, sell and disclose to third parties any such
      material or data for any purpose related to our business. To the extent
      that such material is protected by intellectual property rights, you grant
      us a perpetual, worldwide, royalty-free licence to use, copy, modify,
      distribute, sell and disclose to third parties any such material or data
      for any purpose related to our business.
      <br />
      <br />
      <h1>13. Links From Our Site</h1> Where our Site contains links to other
      sites and resources provided by third parties, these links are provided
      for your information only. We have no control over the contents of those
      sites or resources, and accept no responsibility for them or for any loss
      or damage that may arise from your use of them.
      <br />
      <br />
      <h1>14. Jurisdiction And Applicable Law </h1>The Dutch courts will have
      jurisdiction over any claim arising from, or related to, a visit to our
      Site or use of our Services. These terms of use and any dispute or claim
      arising out of or in connection with them or their subject matter or
      formation (including non-contractual disputes or claims) shall be governed
      by and construed in accordance with the law of the Netherlands.
      <br />
      <br />
      <h1>15. Variations</h1> We may revise these terms of use at any time by
      amending this page. You are expected to check this page from time to time
      to take notice of any changes we make, as they are binding on you.
      <br />
      <br />
      <h1>16. Your Concerns </h1>If you have any concerns about material which
      appears on our Service, please contact marketing@homecookd.org <br />
      <br />
      <h1>HomeCookd Pick up Terms of Service </h1>
      Last updated: 31 July 2022 <br />
      This section (together with the documents referred to in it) tells you the
      terms (the “Terms”) which apply when you order any menu items (the
      “Items”) from our homecookd.org website or mobile applications and related
      services (each referred to as an “Application”) to be collected by you
      from the relevant cooking partners (each a “Pick-up Order”).
      <br /> Please read these Terms carefully before placing any Pick-up Orders
      on our Application. If you have any questions relating to these Terms
      please contact marketing@homecookd.org before you place a Pick-up Order.
      If you are a consumer, you have certain legal rights when you order Items
      using our Application. Your legal rights are not affected by these Terms,
      which apply in addition to them and do not replace them. By setting up
      your HomeCookd account, you confirm that you accept these Terms.
      <br />
      <br />
      <h1>1. Information About Us </h1>homecookd.org is a website operated by
      HomeCookd Ltd (“we”, “us” or “HomeCookd”), incorporated and registered in
      Netherlands, whose registered office is at Zeeburgerstraat 64, Amsterdam,
      Netherlands. HomeCookd is a business where the food is prepared by
      independent cooking partners (our “Partners”) and delivered by us. <br />
      <br />
      <h1>2. Purpose</h1> Our objective is to link you to the Cooking Partners
      that we partner with (“Partners”) and allow you to order Items for
      collection (our “Service”). Where you order from a Partner, HomeCookd acts
      as an agent on behalf of that Partner to conclude your Pick-up Order from
      our Application and to manage your experience throughout the order
      process. Once you have placed a Pick-up Order, you will be required to
      collect your Items directly from the Partner at the location specified in
      the Application (the “Pick-up Location”). In some cases, the Partner may
      be owned by or affiliated with us.
      <br />
      <br />
      <h1>3. Your Account</h1> Before you can place Pick-up Orders using our
      Application, you need to open a HomeCookd account. If you have a HomeCookd
      account for delivery orders, you will use the same account for Pick-up
      Orders. If you open an account for Pick-up Orders, you will also use that
      account for delivery orders. When you open an account you may create a
      password, or other secure login method, and may also have to provide
      credit card details. You must keep any password you create, or other
      secure login method, secret, and prevent others from accessing your email
      account or mobile phone. If another person uses these methods to access
      your account, you will be responsible to pay for any Items they order, and
      we are not responsible for any other losses you suffer, unless the person
      using your password obtained it because we did not keep it secure.
      <br />
      You may close your account at any time by requesting to do so in your
      account section of our website or contacting us using the contact details
      above. We may suspend your access to your account, or close it
      permanently, if we believe that your account has been used by someone
      else. We may also close your account if in our opinion you are abusing our
      Service (for example, by applying for refunds or credit to which we do not
      consider you are entitled, making repeated unreasonable complaints,
      mistreating our staff, or any other good reason). If we close your account
      permanently we will refund any remaining account credit you have validly
      obtained from our customer service team or Application following any issue
      with a Pick-up Order, by applying a credit to your registered credit card,
      or if that is not possible for any reason, by way of a bank transfer using
      bank details (provided you have supplied them to us).
      <br />
      <br />
      <h1>4. Service Availability</h1> Each Partner has a prescribed area within
      which it can accept Pick-up Orders. Our Partners each decide their own
      operating hours. That means that the availability of our Service, and the
      range of Partners from which you can order to pick-up, depends on the
      Partners in your area. If you try to place a Pick-up Order with a Partner
      when you are not in the prescribed area for Pick-up Orders, or you try to
      place a Pick-up Order outside the operating hours of a Partner, or the
      Application is otherwise unavailable for any reason, we will notify you
      that ordering will not be possible.
      <br />
      <br />
      <h1>5. Pick-up Orders </h1>
      When you place a Pick-up Order through our Application, it needs to be
      accepted by us or the Partner before it is confirmed. Your Pick-up Order
      may be rejected at any time. Our Partner may, for example, reject your
      Pick-up Order if they are too busy or the Items you have ordered are
      temporarily unavailable.
      <br />
      You are responsible for paying for all Items ordered using your account
      and for complying with these Terms, even if you have ordered the Item for
      someone else. Some Partners operate a minimum order value policy. This
      will be displayed on our Application. All Items are subject to
      availability. <br />
      Partners may use nuts or other allergens in the preparation of certain
      Items. Increasing numbers of Partners will be displaying dish by dish
      allergens information. Where that information is not available or if you
      have further questions, please contact us prior to ordering if you have an
      allergy. HomeCookd cannot guarantee that any of the Items sold by our
      Partners are free of allergens. <br />
      <br />
      <h1>6. Pick-up</h1> When you place a Pick-up Order which is accepted by
      the Partner, the Partner will tell you the time when your Items are
      expected to be ready for pick-up (“Target Pick-up Time”). The Target
      Pick-up Time is not guaranteed by either us or our Partners. You will be
      required to collect your Items directly from the Partner at the Pick-up
      Location. You must arrive at the Partner to collect your Items at or just
      before the Target Pick-up Time. Our Partners will hold your Items for you
      for 30 minutes after the Target Pick-up Time. If you fail to collect your
      Items within 30 minutes after the Target Pick-up Time, the Partner may
      dispose of your Items. You will still be charged for the Item in the event
      you fail to pick-up your Item from the Partner. <br />
      Unfortunately despite our, and our Partners’, best efforts things do not
      always go to plan and there may be times when your Items are not ready for
      pick-up at the Target Pick-up Time. If your order is not ready more than
      30 minutes after the Target Pick-up Time, we will work with you to make
      things right unless you have caused the delay (e.g. because you changed
      your Pick-up Order after it was confirmed).
      <br />
      <br />
      <h1>7. Your Rights if Something is Wrong With Your Items</h1>
      You have a legal right to receive goods which comply with their
      description, which are of satisfactory quality and which comply with any
      specific requirements you tell us about (and we agree to) before you place
      your Pick-up Order. If you believe that the Items you have picked up do
      not comply with these legal rights, please let us know. We may request a
      photograph showing the problem if it is something that can be seen by
      inspecting the Items. We will provide a refund or account credit in
      respect of the affected part of the Item, unless we have reasonable cause
      to believe that the problem was caused after pick-up.
      <br />
      Prior to processing your refund or account credit, we may take into
      account relevant factors including the details of the Pick-up Order, your
      account history, what happened on or immediately after pick-up and
      information from the Partner.
      <br />
      <br />
      <h1>8. Age Restricted Products</h1> Age restricted products (including,
      without limitation, alcohol, tobacco and cigarettes) can only be sold to
      persons aged 18 or over. By placing a Pick-up Order for an age restricted
      product, you confirm that you are at least 18 years old. HomeCookd
      operates an age verification policy whereby customers who order age
      restricted products will be asked by the Partner to provide proof that
      they are aged 18 or over. Partners may refuse to sell any age restricted
      product to any person unless they can provide a valid photo ID proving
      that they are aged 18 or over. The Partners may also refuse to sell
      alcohol to any person who is, or appears to be under the influence of
      either alcohol or drugs. If the sale of any age restricted product is
      refused, and the Partner confirms to HomeCookd that the relevant Item was
      not provided to you, HomeCookd will process a refund for the relevant Item
      to the card from which you paid for your Pick-up Order. If you paid for
      your Pick-up Order in cash at the Partner, the Partner will process the
      refund for you.
      <br />
      <br />
      <h1>9. Cancellation </h1>If you cancel any Pick-up Order, you will be
      charged the full price for the Items. <br />
      HomeCookd and the Partner may notify you that a Pick-up Order has been
      cancelled at any time. You will not be charged for any Pick-up Orders
      cancelled by us or the Partner, and we will reimburse you for any payment
      already made using the same method you used to pay for your Pick-up Order.
      We may also apply credit to your account to reflect the inconvenience
      caused.
      <br />
      <br />
      <h1>10. Prices, Payment and Offers</h1> Prices include VAT. You confirm
      that you are using our Service for personal, non-commercial use unless you
      request a VAT invoice. HomeCookd may operate dynamic pricing some of the
      time, which means that prices of Items may change while you are browsing.
      Prices can also change at any time at the discretion of the Partners. We
      reserve the right to charge a Service Fee, which may be subject to change,
      for the provision of our Services. You will be notified of any applicable
      Service Fee and taxes prior to purchase on the checkout page on our
      Application. No changes will affect existing confirmed Pick-up Orders,
      unless there is an obvious pricing mistake. Nor will changes to prices
      affect any Pick-up Orders in process and appearing within your basket,
      provided you complete and place the Pick-up Order within 2 hours of
      creating the basket. If you do not conclude the Pick-up Order before the 2
      hour cut-off, the items will be removed from your basket automatically and
      the price change will apply if you re-add the relevant items to your
      basket. If there is an obvious pricing mistake we will notify you as soon
      as we can and you will have the choice of confirming the Pick-up Order at
      the original price or cancelling the Pick-up Order without charge and with
      a full refund of any money already paid. <br />
      The total price of your Pick-up Order will be set out on the checkout page
      on our Application, including the prices of Items and applicable Service
      Fees and taxes. <br />
      We are authorised by our Partners to accept payment on their behalf and
      payment of the price of any Items to us will fulfil your obligation to pay
      the price to the Partner. Payment for all Items can be made on our
      Application by credit or debit card, or such other payment method made
      available by HomeCookd. Once your Pick-up Order has been confirmed, your
      credit or debit card will be authorised and the total amount marked for
      payment. Payment is made directly to HomeCookd acting as agent on behalf
      of the Partner only. Payment may also be made by using vouchers or account
      credit. Use of these is subject to HomeCookd Voucher and Account Credit
      Terms. <br />
      In some cases, you can alternatively make your payment in cash directly to
      the Partner by paying the driver at the time of pick-up. Where cash
      payment is possible, this will be made clear on our Application before you
      place your order. Otherwise, all payments for Pick-up Orders must be made
      on our Application by credit or debit card, or such other payment method
      made available by HomeCookd.
      <br />
      Unless you are permitted to make your payment in cash directly to the
      Partner (in which case you will pay for your Pick-up Order at the time of
      pick-up), you are not required to make any further payments when you
      pick-up your Pick-up Order from the relevant Partner. In the unlikely
      event that a Partner requests additional payments from you on pick-up, do
      not make any such payment and contact us immediately through the
      Application. We have no liability for any payments you make to a Partner
      when collecting your Pick-up Order. <br />
      Partners sometimes make special offers available through our Application.
      These are visible when you look at a Partner menu. These offers are at the
      discretion of the Partners. Unless the offer terms state a fixed or
      minimum period for which an offer will be available, it can be withdrawn
      at any time, unless you have already placed an order based on the offer
      and we have sent the Confirmation Notice.
      <br />
      <br />
      <h1>11. Subscription plan</h1> <br />
      <br />
      <h1>11.1. Billing Cycle</h1> You agree to pay for the plan price set by
      you for the HomeCookd Subscription service, that will be charged to your
      Payment Method on a weekly basis every Friday. In some cases your payment
      date may change, for example if your Payment Method has not successfully
      settled. Visit your account and click on the “Subscription” link to see
      your next payment date.
      <br />
      <h1>11.2. Payment Methods</h1> To use the HomeCookd Subscription service
      you must provide one Payment Method. You are responsible for any
      uncollected amounts. You agree to immediately update your Account in the
      event of any change in your payment information. If a payment is not
      successfully settled, due to expiration, insufficient funds, or otherwise,
      and you do not cancel your account, we may suspend your access to the
      HomeCookd Subscription service until we have successfully charged a valid
      Payment Method. For some Payment Methods, the issuer may charge you
      certain fees, such as foreign transaction fees or other fees relating to
      the processing of your Payment Method. Local tax charges may vary
      depending on the Payment Method used. Check with your Payment Method
      service provider for details.
      <br /> <br />
      <h1>1.3. Updating your Payment Methods</h1>1 You can update your Payment
      Methods by going to the “Account” page. We may also update your Payment
      Methods using information provided by the payment service providers.
      Following any update, you authorize us to continue to charge the
      applicable Payment Method.
      <br />
      <br />
      <h1>11.4. Cancelling your Subscriptions</h1>
      You can cancel your HomeCookd Subscription service at any time and you
      will continue to have access to the HomeCookd Subscription service through
      the end of your Billing Cycle.
      <br /> To cancel, go to the “Account” page and follow the instructions for
      cancellation. If you cancel your subscription, your account will
      automatically be updated at the end of your current billing period.
      <br /> You should take into account that any cancellation should be done
      before the next Billing Cycle: the weekly cut-off time is set at 11 am
      (GMT) every Friday.
      <br /> Payments are non-refundable and we do not provide refunds or
      credits for any already paid but uncollected meals from a Billing Cycle.{" "}
      <br />
      <br />
      <h1>11.5. Changes to the Price and Subscription Plans</h1>
      From time to time we may change our Subscription Plans and the price of
      our service (Meal Prices); however, any price changes or changes to your
      Subscription Plans will apply no earlier than 10 days following our notice
      to you. <br />
      <br />
      <h1>12. Our Responsibility for Loss or Damage That You Suffer</h1> We are
      responsible to you for any loss or damage that you suffer that is a
      foreseeable result of our breaking these Terms or of failing to use
      reasonable care and skill in relation to your use of our Service. We are
      not responsible for any loss or damage that is not foreseeable. Loss or
      damage is “foreseeable” if it is either obvious that it will happen, or if
      you told us that it might happen, for example if you tell us about
      particular circumstances that might increase the loss or damage arising
      from our breach of these Terms before you place an order.
      <br /> We do not exclude or limit our responsibility to you for loss or
      damage where it would be unlawful to do so. This includes any
      responsibility for death or personal injury caused by our failure, or our
      employees’, agents’ or subcontractors’ failure, to use reasonable care and
      skill; for fraud or fraudulent misrepresentation; for breach of your legal
      rights in relation to the Items, as summarised at part 7 above; or for
      defective Items under the Consumer Protection Act. Subject to the previous
      paragraph, we are not responsible for any loss or damage that you suffer
      as a result of your own breach of these Terms or by the “Partners”, or as
      a result of any IT hardware or software failure other than a failure in
      our Applications. 13. Data Protection We process your personal data in
      accordance with our Privacy Policy which can be found on our Site.
      <br />
      <br />
      <h1>13. Other Terms </h1>If either we or you have any right to enforce
      these Terms against the other, that right will not be lost even if the
      person who has the right delays enforcing it, or waive their right to
      enforce it in any instance. If a court or other authority decides that any
      part of these Terms is illegal or ineffective, the rest of the terms will
      be unaffected and will remain in force. <br />
      We may change these Terms from time to time. If we make any changes which
      affect your rights in relation to our Service, we will notify you. Changes
      to the Terms will not affect any orders you have placed where we have sent
      the Confirmation Notice. These Terms are governed by Dutch law and you can
      bring legal proceedings in relation to our Service in the Dutch courts.{" "}
      <br />
      <br />
      <h1>Referral Program Terms </h1>This Referral Programme is open to
      selected HomeCookd customers (‘you’/’user’) aged 18 or over who have made
      at least 1 HomeCookd order.
      <br />
      We will notify you by email/app that you are eligible for the Referral
      Programme. The email will include a unique link. Simply share your unique
      link with a friend or relative (aged 18 or over) who does not live at the
      same address as you and who you think would benefit from the HomeCookd
      service (a “referral”). If anyone to whom you give a referral goes on to
      place a HomeCookd order using that unique link, we will apply to your
      account the amount of HomeCookd Voucher credit stated in the email we sent
      to you with the unique link. It may take up to seven days after your
      relative or friend places a referral order before the Voucher credit is
      applied to your account. Voucher credits received under the Referral
      Program can be redeemed at any Partner and expire automatically within the
      period stated in the promotional materials or other associated HomeCookd
      materials.
      <br />
      Unique links may only be used for personal and non-commercial purposes.
      This means that you can share your unique link with your personal
      connections via your own social media accounts (eg your personal Facebook,
      Twitter or Instagram account) but not on sites where you are a contributor
      but not the account owner (eg Facebook business accounts or groups,
      Wikipedia, Google Reviews, coupon websites). Promoting your referral code
      via Search Engine Marketing (eg AdWords / Yahoo / Bing) is also not
      allowed. If we become aware that you have made your unique link available
      in any of these ways we may deactivate it without telling you. We will not
      be responsible for any losses you suffer if we deactivate your account for
      this reason.
      <br /> We may discontinue the Referral Programme at any time. We will give
      advance notice of discontinuance on our Site. Voucher credit already
      applied to your account as a result of referrals will remain available for
      the period as stated in Clause 2 above, even if the Referral Programme is
      discontinued.
      <br /> Referral credits are Vouchers and are therefore subject to the
      above Voucher Terms and Conditions. <br />
      You may receive Voucher credit for a maximum of 100 referrals. We reserve
      the right to change the maximum number of referrals for which Voucher
      credit is awarded at any time, though you will receive Voucher credit for
      any referral orders placed by your relatives or friends before the change.
      <br />
      <br /> The Promoter is HomeCookd BV, Zeeburgerstraat 64, 1018AG,
      Amsterdam, Netherlands
      {/* Never
      miss home food again. Facebook Instagram Twitter */}
    </div>
  );
};

export default TnC;
