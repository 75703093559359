import React from "react";
import styles from "./Privacy.module.css";

const Privacy = () => {
  return (
    <div className={styles.privacy}>
      <h1 className={styles.hd1} dir="ltr">
        Privacy Statement for Customers, Chefs and Delivery partners
      </h1>
      <hr className={styles.hline} />
      <p className={styles.para}>Last Updated on : Sept 15 , 2022</p>
      <br />
      <br />
      <p className={styles.para}>
        This privacy statement applies to the processing of personal data of
        customers, Chefs and Delivery partners who make use of one of HomeCookd
        (homecookd.org) platforms and products, including order website, app,
        and services, including HomeCookd's platform and services for business
        ("HomeCookd for Business") and HomeCookd's for business Card
        (cullectively, the "Services"). Unless otherwise stated, the data
        contruller for processing these personal data is homecookd.org
        (hereinafter referred to as "HomeCookd"). HomeCookd company is
        registered under Netherlands, Chamber of Commerce - with KVK number
        (86546023), Registered address - Zeeburgerstraat 64, 1018AG, Amsterdam
      </p>
      <br />
      <p className={styles.para} dir="ltr">
        At HomeCookd we are committed to building the world's greatest food
        community, and that begins with protecting the privacy of everyone in
        our community. This means that we'll protect the personal information of
        all visitors that make use of our Services.
      </p>
      <h1 className={styles.hd1}>What personal data we process and why</h1>
      <hr className={styles.hline} />
      <p className={styles.para}>
        Whenever you interact with HomeCookd via our Services we cullect and
        process your personal data. HomeCookd may process your personal data for
        the fullowing purposes.
      </p>
      <ol className={styles.main_list}>
        <li dir="ltr">
          <p className={styles.para} dir="ltr">
            1. Ordering process
            <br />
            <br />
            We process the personal data you provide to us when you place your
            order. This personal data is needed to execute your order, confirm
            your order, and to assess your order, payment, and a possible
            refund. The legal basis for this processing of personal data is that
            it is needed for the performance of a contract as defined in the
            GDPR. We process the fullowing personal data in the ordering
            process:
            <br />
            <br />
          </p>
        </li>
        <ul>
          <li dir="ltr">
            <p className={styles.para} dir="ltr">
              Name
            </p>
          </li>
          <li dir="ltr">
            <p className={styles.para} dir="ltr">
              Address data
            </p>
          </li>
          <li dir="ltr">
            <p className={styles.para} dir="ltr">
              Contact data (Phone and e-mail)
            </p>
          </li>
          <li dir="ltr">
            <p className={styles.para} dir="ltr">
              Order and transaction data
            </p>
          </li>
          <li dir="ltr">
            <p className={styles.para} dir="ltr">
              Payment data (Optional)
            </p>
          </li>
          <li dir="ltr">
            <p className={styles.para} dir="ltr">
              Picture of you (Only if you want to become a chef or a delivery
              partner to identify you and ensure no fraud is committed or any
              false identity is not provided to HomeCookd)
            </p>
          </li>
          <li dir="ltr">
            <p className={styles.para} dir="ltr">
              Valid government ID proof (Only if you want to become a chef or a
              delivery partner to identify you and ensure no fraud is committed
              or any false identity is not provided to HomeCookd)
            </p>
          </li>
          <li dir="ltr">
            <p className={styles.para} dir="ltr">
              VAT Number of your company (Only if you are a chef or Delivery
              partner)
            </p>
          </li>
          <li dir="ltr">
            <p className={styles.para} dir="ltr">
              Contact data (Phone and e-mail)
            </p>
          </li>
          <li dir="ltr">
            <p className={styles.para} dir="ltr">
              Order and transaction data
            </p>
          </li>
          <li dir="ltr">
            <p className={styles.para} dir="ltr">
              KVK Number of your company (Only if you are a chef or Delivery
              partner)
            </p>
          </li>
        </ul>
        <li dir="ltr">
          <p className={styles.para} dir="ltr">
            <br />
            2. Chef reviews
            <br />
            <br />
            After the order process you are asked to provide a review with the
            relevant Chef via the website &amp; email. The legal basis for this
            email, as defined in the GDPR, is your consent unless your consent
            is not necessary according to the applicable law.
            <br />
            HomeCookd processes the personal data you provide when submitting a
            Chef review. The legal basis for the processing of personal data
            when you submit your reviews is the consent provided by you. You can
            withdraw your consent by contacting us via e-mail :
            <a href="mailto:marketing@homecookd.org">marketing@homecookd.org</a>
            . We process the fullowing personal data when you post a Chef
            review:
            <br />
            <br />
          </p>
        </li>
        <ul>
          <li dir="ltr">
            <p className={styles.para} dir="ltr">
              Name (if submitted)
            </p>
          </li>
          <li dir="ltr">
            <p className={styles.para} dir="ltr">
              Contact data
            </p>
          </li>
          <li dir="ltr">
            <p className={styles.para} dir="ltr">
              Review
            </p>
          </li>
        </ul>
        <li dir="ltr">
          <p className={styles.para} dir="ltr">
            <br />
            3. Customer Services
            <br />
            <br />
            When you contact our customer services department, we will use the
            personal data you provide to answer your question or handle your
            complaint. The legal basis for this processing of personal data is
            that it is needed for the performance of a contract and/or to comply
            with HomeCookd's legal obligations as defined in the GDPR. We
            process the fullowing personal data for customer service purposes:
            <br />
            <br />
          </p>
        </li>
        <ul>
          <li dir="ltr">
            <p className={styles.para} dir="ltr">
              Name
            </p>
          </li>
          <li dir="ltr">
            <p className={styles.para} dir="ltr">
              Address data (if applicable)
            </p>
          </li>
          <li dir="ltr">
            <p className={styles.para} dir="ltr">
              Contact data (Phone and e-mail)
            </p>
          </li>
          <li dir="ltr">
            <p className={styles.para} dir="ltr">
              Order and transaction data
            </p>
          </li>
          <li dir="ltr">
            <p className={styles.para} dir="ltr">
              Payment data (if applicable)
            </p>
          </li>
          <li dir="ltr">
            <p className={styles.para} dir="ltr">
              Comments (if applicable)
            </p>
          </li>
        </ul>
        <li dir="ltr">
          <p className={styles.para} dir="ltr">
            <br />
            4. Customer / Chef / Delivery Partner Accounts
            <br />
            <br />
            If you interact with our Services you are given the opportunity to
            create an account with us. The creation of an account is based on
            your consent. You can withdraw your consent and/or delete your
            account by contacting us via marketing@homecookd.org. The withdrawal
            or deletion of your account may have negative consequences on your
            user experience. The fullowing personal data is processed in
            connection with your account:
            <br />
            <br />
          </p>
        </li>
        <ul>
          <li dir="ltr">
            <p className={styles.para} dir="ltr">
              Name
            </p>
          </li>
          <li dir="ltr">
            <p className={styles.para} dir="ltr">
              Address data
            </p>
          </li>
          <li dir="ltr">
            <p className={styles.para} dir="ltr">
              Contact data
            </p>
          </li>
          <li dir="ltr">
            <p className={styles.para} dir="ltr">
              Picture of you (Only if you want to become a chef or a delivery
              partner to identify you and ensure no fraud is committed or any
              false identity is not provided to HomeCookd)
            </p>
          </li>
          <li dir="ltr">
            <p className={styles.para} dir="ltr">
              Valid government ID proof (Only if you want to become a chef or a
              delivery partner to identify you and ensure no fraud is committed
              or any false identity is not provided to HomeCookd)
            </p>
          </li>
          <li dir="ltr">
            <p className={styles.para} dir="ltr">
              VAT Number of your company (Only if you are a chef or Delivery
              partner)
            </p>
          </li>
          <li dir="ltr">
            <p className={styles.para} dir="ltr">
              Contact data (Phone and e-mail)
            </p>
          </li>
          <li dir="ltr">
            <p className={styles.para} dir="ltr">
              Order and transaction data
            </p>
          </li>
          <li dir="ltr">
            <p className={styles.para} dir="ltr">
              KVK Number of your company (Only if you are a chef or Delivery
              partner)
            </p>
          </li>
        </ul>
        <li dir="ltr">
          <p className={styles.para} dir="ltr">
            <br />
            5. Customer research
            <br />
            <br />
            To make sure that our services are aligned with your preferences and
            to improve our services and platforms, HomeCookd may approach you to
            conduct customer research; such as but not limited to, surveys. We
            only send you these types of communications with your prior consent,
            unless this is not necessary according to the applicable law.
            Participation in the customer satisfaction surveys is completely
            vuluntary. If you do not wish to receive these surveys you can
            unsubscribe from them in the messages itself. HomeCookd may
            processes the fullowing personal data for research purposes:
            <br />
            <br />
          </p>
        </li>
        <ul>
          <li dir="ltr">
            <p className={styles.para} dir="ltr">
              Name
            </p>
          </li>
          <li dir="ltr">
            <p className={styles.para} dir="ltr">
              Contact data
            </p>
          </li>
          <li dir="ltr">
            <p className={styles.para} dir="ltr">
              Order and transaction data
            </p>
          </li>
          <li dir="ltr">
            <p className={styles.para} dir="ltr">
              Research input
            </p>
          </li>
        </ul>
        <li dir="ltr">
          <p className={styles.para} dir="ltr">
            <br />
            6. Marketing
            <br />
            <br />
            We also process your personal data to be able to send you
            (personalised) marketing messages and notifications. Such messages
            include the latest news, discounts, and updates about new Chefs (by
            email or push notification), regardless of the format we use to
            share these kinds of messages (including email or push messages).
            The legal basis for this processing of personal data, as defined in
            the GDPR, is your consent provided during the ordering process
            unless your consent is not necessary according to the applicable
            law. Whenever you want to change your preferences with respect to
            receiving such messages and notifications, you can unsubscribe using
            the unsubscribe link in the messages or contact us via
            marketing@homecookd.org. We process the fullowing personal data for
            marketing purposes:
            <br />
            <br />
          </p>
        </li>
        <ul>
          <li dir="ltr">
            <p className={styles.para} dir="ltr">
              Name
            </p>
          </li>
          <li dir="ltr">
            <p className={styles.para} dir="ltr">
              Address data
            </p>
          </li>
          <li dir="ltr">
            <p className={styles.para} dir="ltr">
              Contact data
            </p>
          </li>
          <li dir="ltr">
            <p className={styles.para} dir="ltr">
              Order and transaction data
            </p>
          </li>
          <li dir="ltr">
            <p className={styles.para} dir="ltr">
              Review(s) (optional)
            </p>
          </li>
          <li dir="ltr">
            <p className={styles.para} dir="ltr">
              Campaign data (optional)
            </p>
          </li>
          <li dir="ltr">
            <p className={styles.para} dir="ltr">
              DeviceID
            </p>
          </li>
          <li dir="ltr">
            <p className={styles.para} dir="ltr">
              Cookie and Technulogy data
            </p>
          </li>
        </ul>
        <li dir="ltr">
          <p className={styles.para} dir="ltr">
            <br />
            7. Cookies
            <br />
            <br />
            HomeCookd uses cookies or similar technulogies for functional,
            analytical, and marketing purposes. Data processed for cookie
            purposes defer per purpose (functional, analytical or marketing) and
            depend on the preferences set by you.
            <br />
            Please refer to our Cookie Statement for further information about
            our use of cookies and to set your preferences.
          </p>
        </li>
        <li dir="ltr">
          <p className={styles.para} dir="ltr">
            <br />
            8. Fraud prevention
            <br />
            <br />
            We also process personal data to prevent fraud and other forms of
            misuse on and via our Services. The legal basis for this processing
            operation is that it is needed in pursuing a legitimate interest of
            HomeCookd (fraud prevention) as defined in the GDPR. Personal data
            processed for fraud prevention purposes are:
            <br />
            <br />
          </p>
        </li>
        <ul>
          <li dir="ltr">
            <p className={styles.para} dir="ltr">
              Name
            </p>
          </li>
          <li dir="ltr">
            <p className={styles.para} dir="ltr">
              Order and transaction data
            </p>
          </li>
          <li dir="ltr">
            <p className={styles.para} dir="ltr">
              Payment data
            </p>
          </li>
        </ul>
        <li dir="ltr">
          <p className={styles.para} dir="ltr">
            <br />
            9. Analysis
            <br />
            <br />
            HomeCookd uses your personal data to be able to meet our reporting
            obligations towards advertisers and to be able to improve our
            website and application and our range of products and services. The
            legal basis for this processing operation is that it is needed in
            pursuing a legitimate interest of HomeCookd (analysis &amp;
            reporting) as defined in the GDPR. We will always make sure the
            reports do not contain any personal data so that the information
            cannot be traced back to you.
          </p>
        </li>
        <li dir="ltr">
          <p className={styles.para} dir="ltr">
            <br />
            10. Campaigns
            <br />
            <br />
            HomeCookd may launch specific campaigns or contents in connection to
            its Services. If you wish to enter a HomeCookd campaign, HomeCookd
            will always ask you for your permission to process your personal
            data. The legal basis for this processing activity is your consent.
            You can always withdraw your consent by contacting us via the
            contact data provided to you within the campaign or by filling out a
            form under Reach us section or contact us via
            marketing@homecookd.org. HomeCookd processes the fullowing personal
            data for campaigning purposes:
            <br />
            <br />
          </p>
        </li>
        <ul>
          <li dir="ltr">
            <p className={styles.para} dir="ltr">
              Name
            </p>
          </li>
          <li dir="ltr">
            <p className={styles.para} dir="ltr">
              Contact data
            </p>
          </li>
          <li dir="ltr">
            <p className={styles.para} dir="ltr">
              Order and transaction data (optional)
            </p>
          </li>
          <li dir="ltr">
            <p className={styles.para} dir="ltr">
              Campaign data (optional)
            </p>
          </li>
        </ul>
      </ol>
      <h1 className={styles.hd1} dir="ltr">
        Additional purposes
      </h1>
      <hr className={styles.hline} />
      <p className={styles.para}>
        We will only use your personal data for the purposes described above. If
        we want to use the data for a different purpose, we will take
        appropriate measures to inform you, consistent with the significance of
        the changes we make. Where required by applicable data protection law,
        we will obtain your consent.
      </p>
      <br />
      <h1 className={styles.hd1} dir="ltr">
        Age
      </h1>
      <hr className={styles.hline} />
      <p className={styles.para}>
        Our website and application is neither intended for persons aged under
        16, and nor do we intend to cullect personal data of website visitors
        who are aged under 16. However, we are unable to verify visitors' age.
        We therefore advise parents to monitor their children's online
        activities, to prevent their personal data being cullected without
        parental consent. If you feel that we have cullected personal data of a
        minor without consent, please contact us via marketing@homecookd.org. We
        will then proceed to erase this data.
      </p>
      <br />
      <h1 className={styles.hd1} dir="ltr">
        Automated decision-making and profiling
      </h1>
      <hr className={styles.hline} />
      <p className={styles.para}>
        In the performance of the contract with you and to improve our platform,
        HomeCookd uses automated decision-making and profiling. For example,
        HomeCookd uses your address data and/or location data to select
        available Chefs in your local area. We also use automated
        decision-making in complying with our legal obligations to prevent money
        laundering, terrorism financing, and other criminal offences.
      </p>
      <br />
      <br />
      <p className={styles.para}>
        When such automated decision-making and/or profiling leads to a negative
        decision about you, and you do not agree with it, you can contact us via
        marketing@homecookd.org. We will then proceed to reassess the situation.
      </p>
      <p className={styles.para} dir="ltr">
        How long we store personal data for
      </p>
      <hr className={styles.hline} />
      <p className={styles.para}>
        We will only retain your personal data for as long as necessary to
        fulfil the purposes we cullected it for, including for the purposes of
        satisfying any legal, tax, accounting, or reporting requirements. To
        determine the appropriate retention period for personal data, we
        consider the amount, nature, and sensitivity of the personal data, the
        potential risk of harm from unauthorised use or disclosure of your
        personal data, the purposes for which we process your personal data and
        whether we can achieve those purposes through other means, and the
        applicable legal requirements.
      </p>
      <br />
      <p className={styles.para} dir="ltr">
        In some circumstances we may anonymise your personal data so that it can
        no longer be associated with you, in which case we may use such
        information without further notice to you.
        <br />
        If you have any questions on the way we retain your personal data you
        can contact us via{" "}
        <a href="mailto:marketing@homecookd.org">marketing@homecookd.org</a>.
        <br />
        <br />
        Sharing with Chefs
      </p>
      <hr className={styles.hline} />
      <p className={styles.para}>
        If you order via our Services, HomeCookd will share your personal data
        (order, name, address data and telephone number) with the Chef you
        selected, so that the Chef can deliver your order. As you are a direct
        customer of the Chef, the Chef will have its own responsibility and
        obligations with respect to the processing of your personal data. If you
        have questions about how the Chef handles your personal data, you should
        contact the Chef directly.
      </p>
      <h1 className={styles.hd1} dir="ltr">
        Sharing with others (not being Chefs)
      </h1>
      <hr className={styles.hline} />
      <p className={styles.para}>
        HomeCookd may work with other companies within the HomeCookd group and
        other third parties in order to carry out the processing described above
        and/or to comply with legal obligations.
      </p>
      <br />
      <p className={styles.para}>
        These group companies and third parties will process your personal data
        on behalf of us (as data processors) or as autonomous data contrullers,
        and have access to your personal data for the purposes described in this
        privacy notice. We require group companies and third parties to protect
        your personal data in accordance with the standards set out in this
        privacy notice and we take appropriate measures pursuant to the
        applicable data protection law to guarantee the same level of protection
        and confidentiality.
      </p>
      <br />
      <p className={styles.para} dir="ltr">
        We will also share your data with other third party data contrullers
        where appropriate or required by applicable law or regulation (including
        a court order) or where we believe disclosure is necessary to comply
        with legal obligations, to exercise, establish or defend legal rights or
        to protect the vital interests of any person. Such third party
        contrullers may include law enforcement agencies.
        <br />
        <br />
        We may also disclose your data to any company or prospective buyer of
        all or substantially all of our assets in connection with any sale or
        transfer of those assets.
        <br />
        Your personal data may be shared with the fullowing parties.
      </p>
      <ul>
        <li dir="ltr">
          <p className={styles.para} dir="ltr">
            HomeCookd's affiliates, subsidiaries and/or group companies of
            HomeCookd
          </p>
        </li>
        <li dir="ltr">
          <p className={styles.para} dir="ltr">
            Software providers
          </p>
        </li>
        <li dir="ltr">
          <p className={styles.para} dir="ltr">
            Implementation partners
          </p>
        </li>
        <li dir="ltr">
          <p className={styles.para} dir="ltr">
            Payment and card service providers
          </p>
        </li>
        <li dir="ltr">
          <p className={styles.para} dir="ltr">
            Customer satisfaction survey firms
          </p>
        </li>
        <li dir="ltr">
          <p className={styles.para} dir="ltr">
            Professional advisors
          </p>
        </li>
        <li dir="ltr">
          <p className={styles.para} dir="ltr">
            Law enforcement, regulatory bodies and/or government agencies
          </p>
        </li>
        <li dir="ltr">
          <p className={styles.para} dir="ltr">
            Prospective buyers
          </p>
        </li>
        <li dir="ltr">
          <p className={styles.para} dir="ltr">
            Any other third party provided that you have given your consent to
            the disclosure
          </p>
        </li>
      </ul>
      <h1 className={styles.hd1} dir="ltr">
        Where your data gets sent
      </h1>
      <hr className={styles.hline} />
      <p className={styles.para}>
        We may transfer your personal data outside of the European Economic Area
        (EEA). This may include transferring it to or accessing it from other
        jurisdictions, including jurisdictions that may not provide a level of
        protection equivalent to the EU data protection laws. Where we transfer
        personal data outside the EEA we will take into account any applicable
        statutory obligations relevant to personal data transfers and, where
        there is no European Commission (EC) adequacy decision in place, we will
        rely on appropriate safeguards, including EC approved standard
        contractual clauses. We have implemented similar appropriate safeguards
        with our third party service providers and further data can be provided
        upon request.
      </p>
      <br />
      <h1 className={styles.hd1} dir="ltr">
        Personal data rights, questions or complaints
      </h1>
      <hr className={styles.hline} />
      <p className={styles.para}>
        We inform you that, with regard to the personal data, you can exercise
        the rights provided for by applicable data protection laws, which
        include: the right to request access to, rectification of, or erasure of
        personal data or restriction of processing, and to object to processing,
        the right to data portability, to withdraw your consent at any time
        (without prejudice to the lawfulness of the processing based on the
        consent given prior to the withdrawal) as well as to lodge a complaint
        with a supervisory authority.
      </p>
      <br />
      <p className={styles.para}>
        To exercise your rights please contact us via
      </p>
      <a href="mailto:marketing@homecookd.org">marketing@homecookd.org</a>.
      <br />
      <p className={styles.para}>
        If you have any other questions or complaints about the processing of
        your personal data, we will be happy to talk to you. We would also like
        to hear from you if you have tips or suggestions on how to improve our
        privacy pulicy.
      </p>
      <br />
      <br />
      <h1 className={styles.hd1}>Security</h1>
      <hr className={styles.hline} />
      <p className={styles.para}>
        HomeCookd takes personal data protection very seriously and we therefore
        take appropriate measures to protect your personal data against misuse,
        loss, unauthorised access, unwanted disclosure, and unauthorised
        alteration. If you feel that your personal data are not adequately
        protected or there are indications of misuse, please contact us via
        marketing@homecookd.org.
      </p>
      <br />
      <h1 className={styles.hd1} dir="ltr">
        Data Protection Authority
      </h1>
      <hr className={styles.hline} />
      <p className={styles.para}>
        Besides the option of lodging a complaint with us, you have the right to
        lodge a complaint with the relevant supervisory authority for the
        protection of personal data. To do so, contact the supervisory authority
        directly.
      </p>
      <br />
      <br />
      <h1 className={styles.hd1}>How to contact us</h1>
      <hr className={styles.hline} />
      <p className={styles.para}>
        If you have any questions or concerns about this Privacy Statement
        and/or our privacy practices, please contact us via e-mail -
      </p>
      <a href="mailto:marketing@homecookd.org">
        marketing@homecookd.org
        <br />
        <br />
      </a>
      <p className={styles.para}>
        HomeCookd, Zeeburgerstraat 64, 1018AG, Netherlands
      </p>
      <br />
      <br />
      <br />
      <h1 className={styles.hd1}>Updates to this privacy Statement</h1>
      <hr className={styles.hline} />
      <p className={styles.para}>
        We may update this privacy Statement from time to time in response to
        changing legal, technical or business developments. When we update our
        privacy Statement, we will take appropriate measures to inform you,
        consistent with the significance of the changes we make. Where required
        by applicable data protection law, we will obtain your consent to any
        material changes to this privacy Statement.
      </p>
      <br />
      <p className={styles.para}>
        We encourage you to periodcally review this Statement for the latest
        information on our privacy practices.
      </p>
      <br />
      <br />
      <h2 className={styles.hd2}>Contact us:</h2>
      <br />
      <h2 className={styles.hd2}>marketing@homecookd.org</h2>
      <br />
    </div>
  );
};

export default Privacy;
