import React from "react";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import Top from "../../Components/Top/Top";
import About from "../About/About";
import Forth from "../Forth/Forth";
import Home from "../Home/Home";
import How from "../How/How";
import Third from "../Third/Third";

function Main() {
  return (
    <div>
      <Top />
      <Header />
      <Home />
      <How />
      <Third />
      <Forth />
      <About />
      <Footer />
    </div>
  );
}

export default Main;
