import "./App.css";
// import Header from "./Components/Header/Header";
// import Home from "./Pages/Home/Home";
// import How from "./Pages/How/How";
// import Third from "./Pages/Third/Third";
// import Forth from "./Pages/Forth/Forth";
// import About from "./Pages/About/About";
// import Footer from "./Components/Footer/Footer";
// import Top from "./Components/Top/Top";
import { Routes, Route } from "react-router-dom";
import TnC from "./Pages/TnC/TnC";

import Main from "./Pages/Main/Main";
import Privacy from "./Pages/Privacy/Privacy";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" exact element={<Main />} />
        <Route path="terms&amp;conditions" element={<TnC />} />
        <Route path="privacy" element={<Privacy />} />
      </Routes>
    </div>
  );
}

export default App;
